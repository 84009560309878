import { useState, useCallback, useEffect } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { CameraIcon } from 'lucide-react';
import { useDropzone } from 'react-dropzone';
import { AiOutlineLoading } from 'react-icons/ai';
import Modal from '@/components/Modals/Modal';
import { Button } from '@/components/ui/button';
import { IMedication, IRequest } from '@/types';
import axiosFileUpload from '@/utils/axiosFileUpload';

interface MedicationPhotoModalProps {
  request: IRequest;
  medications: IMedication[];
}

const MedicationPhotoModal = NiceModal.create(({ request, medications }: MedicationPhotoModalProps) => {
  const modal = useModal('medication-photo-modal');
  const medicationModal = useModal('medication-modal');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [uploadedImage, setUploadedImage] = useState<string | null>(null);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];
      const formData = new FormData();
      formData.append('prescription[image]', file);
      formData.append('prescription[request_id]', request.id);

      setUploadedImage(URL.createObjectURL(file));
      setIsLoading(true);

      axiosFileUpload
        .post('/v2/prescriptions', formData)
        .then((response) => {
          if (response.data.error?.length > 0) {
            setIsLoading(false);
            setUploadedImage(null);
            setError('We could not detect enough information from the image. Please try another image.');
          } else {
            const medication = response.data;
            medicationModal.show({ request, medication: medication, medications: medications });
            modal.remove();
          }
        })
        .catch((error) => {
          console.error('Error:', error);
          setIsLoading(false);
          setUploadedImage(null);
          setError('We could not detect enough information from the image. Please try another image.');
        });
    },
    [modal, medicationModal, request, medications],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  // hit the warmup endpoint to wake up the server before the user uploads an image to process
  useEffect(() => {
    axiosFileUpload.get('/v2/prescriptions/warmup');
  }, []);

  return (
    <Modal
      modal={modal}
      title={'Create your prescription with a photo!'}
      description={'Snap a photo of your written prescription and we will fill out all of the details for you.'}
      wide
    >
      {error && <div className="w-1/2 p-4 mx-auto text-center text-red-500">{error}</div>}

      <div {...getRootProps()} className="upload-container">
        <input {...getInputProps()} />
        <div className="flex flex-col items-center justify-center gap-2">
          {uploadedImage ? (
            <>
              <div className="relative">
                <div className="rounded-lg ">
                  <img src={uploadedImage} alt="Uploaded" className="object-fill rounded-lg" />
                </div>
              </div>

              <div className="text-sm font-semibold">Uploading and analyzing your image...</div>
              <Button
                onClick={(e) => {
                  e.preventDefault();

                  setUploadedImage(null);
                  setIsLoading(false);
                }}
                className="mt-4"
              >
                Try Again
              </Button>
            </>
          ) : (
            <>
              {isLoading ? (
                <div className="flex flex-col items-center justify-center gap-8">
                  <AiOutlineLoading className="w-12 h-12 animate-spin" />
                  <div className="text-sm font-semibold">Extracting all medication data from your image...</div>
                </div>
              ) : (
                <>
                  <CameraIcon className="w-8 h-8" />
                  <div className="text-sm font-semibold">Upload Prescription</div>
                  <div className="mt-4 text-center max-w-80 text-pretty">
                    {isDragActive ? (
                      <p>Drop the photo here...</p>
                    ) : (
                      <p>Drag and drop your photo here, or click to upload a photo of your prescription.</p>
                    )}
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </Modal>
  );
});

export default MedicationPhotoModal;
