import { IRequest } from '@/types.ts';
import { getUrl } from '../utils/api';

function useRequest(id: string, admin = false) {
  if (!id) return undefined;
  let url = `v1/requests/${id}`;
  if (admin) url = `admin/requests/${id}`;

  const query = getUrl(url);

  return {
    ...query,
    data: query.data as IRequest,
  };
}

export default useRequest;
