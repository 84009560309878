import { Label } from '@/components/ui/label';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';

interface DosageProps {
  prescription: any;
  update: (key: string, value: string) => void;
  drug: any;
}

const Dosage = ({ prescription, update, drug }: DosageProps) => {
  const handleChange = (dosage: string) => {
    update('dosage', dosage);
  };

  const variant = drug?.variants?.find((v: any) => v.name === prescription.variant);
  return (
    <>
      <Label>What dosage is this written for?</Label>
      <Select onValueChange={handleChange} value={prescription.dosage || ''}>
        <SelectTrigger>
          <SelectValue placeholder={`What dosage is prescribed?`} />
        </SelectTrigger>
        <SelectContent>
          {variant?.dosages?.map((dosage: any) => (
            <SelectItem key={variant.name + dosage} value={dosage}>
              {dosage}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </>
  );
};

export default Dosage;
