import { useParams } from 'react-router';


const Onboarding = () => {
  const { step } = useParams();



  if (!step) {
    window.location.href = '/login';
    return null;
  }


  return (
    <>
      Onboarding {step}
    </>
  );
};

export default Onboarding;
