import CreditPackages from '../Requests/CreditPackages';
import Section from '../Section';

const CreditsCard = () => {
  return (
    <>
      <Section title={'Purchase Credits'}>
        <CreditPackages />
      </Section>
    </>
  );
};

export default CreditsCard;
