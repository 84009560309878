import { Label } from '@/components/ui/label';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';

interface PreferenceProps {
  prescription: any;
  update: (key: string, value: string) => void;
  drug: any;
}

const Preference = ({ prescription, update, drug }: PreferenceProps) => {
  const handleChange = (preference: string) => {
    update('preference', preference);
  };

  const drugPreferences = drug?.generic ? ['Brand Name', 'Generic', 'No Preference'] : ['Brand Name'];

  return (
    <>
      <Label>What is your preference to brand name or generic?</Label>
      <Select onValueChange={handleChange} value={prescription.preference || ''}>
        <SelectTrigger>
          <SelectValue placeholder={`Brand or Generic?`} />
        </SelectTrigger>
        <SelectContent>
          {drugPreferences.map((preference: any) => (
            <SelectItem key={preference} value={preference}>
              {preference === 'Brand Name' && `I only want brand name ${drug.name}`}
              {preference === 'Generic' && `I only want generic ${drug.generic}`}
              {preference === 'No Preference' && `I have no preference to brand name or generic`}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </>
  );
};

export default Preference;
