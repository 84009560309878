import Login from '@/components/External/Login.tsx';
import MFA from '@/components/External/MFA.tsx';
import Onboarding from '@/components/Onboarding/Onboarding.tsx';
import OnboardingSubscription from '@/components/Onboarding/OnboardingSubscription.tsx';
import RequestCreator from '@/components/Requests/RequestCreator.tsx';
import RequestLoader from '@/components/Requests/RequestLoader.tsx';
import Logout from './components/External/Logout.tsx';
import ExternalWrapper from './components/ExternalWrapper.tsx';
import ProfileSetup from './components/ProfileSetup.tsx';
import Requests from './components/Requests/List.tsx';
import Settings from './components/Settings/Settings.tsx';
import Application from './pages/Application';
import Medications from './pages/Medications.tsx';

const routes = [
  {
    url: '/',
    element: <Application />,
    private: false,
  },
  {
    url: '/login',
    exact: true,
    element: (
      <ExternalWrapper>
        <Login />
      </ExternalWrapper>
    ),
    private: false,
  },
  {
    url: '/mfa/:method/:value/:token',
    exact: true,
    element: (
      <ExternalWrapper>
        <MFA />
      </ExternalWrapper>
    ),
    private: false,
  },

  {
    url: '/onboarding/subscription',
    exact: true,
    element: <OnboardingSubscription />,
    private: true,
  },
  {
    url: '/onboarding/profile',
    exact: true,
    element: <ProfileSetup />,
    private: true,
  },
  {
    url: '/onboarding/:step',
    exact: true,
    element: <Onboarding />,
    private: true,
  },
  {
    url: '/logout',
    exact: true,
    element: <Logout />,
    private: true,
  },
  {
    url: '/medications',
    exact: true,
    element: <Medications />,
    private: true,
  },
  {
    url: '/memberships/success',
    exact: true,
    element: <Settings />,
    private: true,
  },
  {
    url: '/memberships/cancel',
    exact: true,
    element: <Settings />,
    private: true,
  },
  {
    url: '/memberships',
    exact: true,
    element: <Settings />,
    private: true,
  },
  {
    url: '/credits/success',
    exact: true,
    element: <Settings />,
    private: true,
  },
  {
    url: '/credits/cancel',
    exact: true,
    element: <Settings />,
    private: true,
  },
  {
    url: '/credits',
    exact: true,
    element: <Settings />,
    private: true,
  },

  {
    url: '/requests',
    exact: true,
    element: <Requests />,
    private: true,
  },
  {
    url: '/requests/new',
    exact: true,
    element: <RequestCreator />,
    private: true,
  },
  {
    url: '/requests/:requestId/success',
    exact: true,
    element: <RequestLoader />,
    private: true,
  },
  {
    url: '/requests/:requestId/cancel',
    exact: true,
    element: <RequestLoader />,
    private: true,
  },
  {
    url: '/requests/:requestId',
    exact: true,
    element: <RequestLoader />,
    private: true,
  },
];

export { routes };
