import { PlusIcon } from '@heroicons/react/24/outline';
import { ArrowLeft } from 'lucide-react';
import { Link } from 'react-router-dom';
import Loading from '@/components/Loading';
import { Button } from '@/components/ui/button';
import useRequests from '@/hooks/useRequests.ts';
import ListItem from './ListItem';

const List = () => {
  const requests = useRequests();

  return (
    <>
      <div className="flex items-center justify-start gap-1 mt-4 mb-6 sm:mt-2 ">
        <div className="mr-2 leading-snug text-gray-600 hover:text-violet-600">
          <Link to="/">
            <ArrowLeft />
          </Link>
        </div>
        <div className="font-medium leading-snug text-violet-600">My Orders</div>
      </div>

      <div className="flex flex-col-reverse justify-between gap-8 mb-8 sm:flex-col sm:gap-4 md:flex-row">
        <div className="flex flex-col">
          <div className="text-3xl font-bold leading-8 text-neutral-800 sm:text-2xl text-pretty">
            Here are your orders
          </div>
          <div className="mt-1 text-sm font-normal leading-normal text-gray-500 sm:max-w-sm md:max-w-lg text-pretty">
            You can view the status of your orders here. Click on an order to view more details. You can also add a new
            order by clicking the button below.
          </div>
        </div>
        <div className="flex items-center justify-end">
          <Button className="w-full gap-1 rounded-full sm:w-auto whitespace-nowrap" asChild>
            <Link to="/requests/new">
              <PlusIcon className="w-5 h-5" />
              Add New Order
            </Link>
          </Button>
        </div>
      </div>

      <div className="grid grid-cols-1 gap-8 lg:grid-cols-2">
        {requests?.data?.map((request, index) => <ListItem key={request.id} request={request} index={index} />)}
      </div>

      {requests.isLoading && (
        <div className="flex justify-center w-full mb-8">
          <Loading />
        </div>
      )}

      {requests?.data?.length === 0 && (
        <div className="flex flex-col w-full gap-2 px-2 py-4 text-xs text-center text-gray-600">
          <div>You have not created an order.</div>
          <div>Click the button above to create one.</div>
        </div>
      )}
    </>
  );
};

export default List;
