import React, { useState, useEffect } from 'react';
import posthog from 'posthog-js';
import { useNavigate } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Switch } from '@/components/ui/switch';
import useMe from '@/hooks/useMe';
import useRequests from '@/hooks/useRequests';
import axiosClient from '@/utils/axiosClient';

const ProfileSetup: React.FC = () => {
  const { data: currentUser, isLoading: isLoadingUser, isError: isErrorUser } = useMe();
  const { data: requests, isLoading: isLoadingRequests, isError: isErrorRequests } = useRequests();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [isMedicalProvider, setIsMedicalProvider] = useState(false);
  const [consentToSMS, setConsentToSMS] = useState(false);
  const [recommendToFriend, setRecommendToFriend] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showRecommendSwitch, setShowRecommendSwitch] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser) {
      setFirstName(currentUser.first_name || '');
      setLastName(currentUser.last_name || '');
      setPhone(currentUser.phone || '');
    }

    if (requests) {
      const hasMultipleRequests = requests.length > 1;
      const hasPaidSingleRequest = requests.some((request) => request.status === 'complete');
      setShowRecommendSwitch(hasMultipleRequests || hasPaidSingleRequest);
    }
  }, [currentUser, requests]);

  const clean_phone = (phone: string) => {
    return phone.replace(/\D/g, '');
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const response = await axiosClient.post('/v2/profile', {
        first_name: firstName,
        last_name: lastName,
        phone: clean_phone(phone),
        zip: zipCode,
        medical_provider: isMedicalProvider,
        prefers_sms: consentToSMS,
        recommend_to_friend: recommendToFriend,
      });

      if (response.data && response.data.length === 1) {
        posthog.capture('onboarding_complete');
        navigate(`/requests/${response.data[0]}`);
      } else {
        navigate('/requests');
      }
    } catch (error) {
      console.error('Error saving profile:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (isLoadingUser || isLoadingRequests) {
    return <div>Loading...</div>;
  }

  if (isErrorUser || isErrorRequests) {
    return <div>Error loading user data</div>;
  }

  return (
    <div className="flex flex-col items-start gap-8 p-2 sm:p-6 relative bg-[#f7f7f7]">
      <div className="flex flex-col items-start gap-5 relative self-stretch w-full flex-[0_0_auto]">
        <div className="flex items-center gap-1 relative self-stretch w-full flex-[0_0_auto]">
          <div className="inline-flex items-center gap-1 relative flex-[0_0_auto]">
            <div className="relative w-fit mt-[-1.00px] font-text-m-medium text-brand-purple text-[15px] tracking-[0] leading-[22px] whitespace-nowrap">
              Welcome to Needle
            </div>
          </div>
        </div>
        <div className="flex items-end relative self-stretch w-full flex-[0_0_auto]">
          <div className="relative flex flex-col items-start flex-1 gap-2 grow">
            <div className="relative self-stretch mt-[-1.00px] font-bold text-[32px] tracking-[-0.20px] leading-[38.4px]">
              Let's finish setting up your profile...
            </div>
            <p className="relative self-stretch font-text-l-regular text-[16px] tracking-[0] leading-[24px] text-gray-600">
              To provide you with the best service, would you please provide some additional information about yourself?
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-start gap-8 self-stretch w-full relative flex-[0_0_auto]">
        <div className="gap-4 self-stretch w-full flex-[0_0_auto] flex flex-col items-start relative">
          <div className="flex items-start gap-8 self-stretch w-full relative flex-[0_0_auto]">
            <div className="flex-1 grow">
              <Label className="block mb-2 text-sm font-medium text-gray-700">First Name</Label>
              <Input
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                className="w-full p-3 pl-4 text-gray-700 bg-white border border-gray-300 rounded-full"
                required
                autoFocus
              />
            </div>
            <div className="flex-1 grow">
              <Label className="block mb-2 text-sm font-medium text-gray-700">Last Name</Label>
              <Input
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                className="w-full p-3 pl-4 text-gray-700 bg-white border border-gray-300 rounded-full"
              />
            </div>
          </div>
        </div>

        <div className="relative flex flex-row items-start self-stretch w-full gap-8">
          <div className="w-full">
            <Label className="block mb-2 text-sm font-medium text-gray-700">Phone Number</Label>
            <div className="relative flex items-center self-stretch w-full h-12 gap-2 px-4 py-3 overflow-hidden bg-white border border-gray-300 border-solid rounded-full hover:">
              <div className="relative flex items-center flex-1 gap-2 grow">
                <span className="text-base text-gray-500">+1</span>
                <Input
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  className="flex-1 pl-2 text-gray-700 bg-transparent border-none"
                  placeholder="555444433333"
                />
              </div>
            </div>
          </div>

          <div className="w-full">
            {requests.length == 1 && (
              <>
                <Label className="block mb-2 text-sm font-medium text-gray-700">Zip Code</Label>
                <div className="relative flex items-center self-stretch w-full h-12 gap-2 px-4 py-3 overflow-hidden bg-white border border-gray-300 border-solid rounded-full">
                  <Input
                    value={zipCode}
                    onChange={(e) => setZipCode(e.target.value)}
                    className="flex-1 text-gray-700 bg-transparent border-none ring-0 focus:ring-0 focus:ring-transparent focus:border-transparent"
                    placeholder="Zip Code"
                    maxLength={5}
                  />
                </div>
              </>
            )}
          </div>
        </div>

        <div className="flex flex-col items-start gap-4 cursor-pointer" onClick={() => setConsentToSMS(!consentToSMS)}>
          <div className="inline-flex items-center gap-2 relative flex-[0_0_auto]">
            <Switch checked={consentToSMS} />
            <p className="relative w-fit mt-[-1.00px] text-sm font-medium text-gray-700 whitespace-nowrap">
              Do you consent to text messages?
            </p>
          </div>
          <p className="text-sm text-gray-600 ml-14">
            Get ready to send us a text message letting us know you need your medications in the future but we need your
            consent to text you prior.
          </p>
        </div>

        <div
          className="flex flex-col items-start gap-4 cursor-pointer"
          onClick={() => setIsMedicalProvider(!isMedicalProvider)}
        >
          <div className="inline-flex items-center gap-2 relative flex-[0_0_auto]">
            <Switch checked={isMedicalProvider} />

            <div className="relative w-fit mt-[-1.00px] text-sm font-medium text-gray-700 whitespace-nowrap">
              Are you a medical provider?
            </div>
          </div>
          <p className="text-sm text-gray-600 ml-14">
            Let us know if you're a medical provider so we can better assist you in helping your patients. We offer
            specialized services and updates tailored to your needs.
          </p>
        </div>

        {showRecommendSwitch && (
          <div
            className="inline-flex items-center gap-2 relative flex-[0_0_auto]"
            onClick={() => setRecommendToFriend(!recommendToFriend)}
          >
            <Switch checked={recommendToFriend} />
            <p className="relative w-fit mt-[-1.00px] text-sm font-medium text-gray-700 whitespace-nowrap">
              Would you recommend Needle to a friend?
            </p>
          </div>
        )}

        <div className="inline-flex items-center justify-end gap-2 relative flex-[0_0_auto] cursor-pointer w-full mt-2">
          <Button onClick={handleSubmit} disabled={isSubmitting} className="w-full sm:w-fit">
            {isSubmitting ? 'Saving...' : 'Next Step'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ProfileSetup;
