import { Redo2, TrashIcon } from 'lucide-react';
import posthog from 'posthog-js';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog.tsx';
import { IRequest, ITask } from '@/types.ts';
import axiosClient from '@/utils/axiosClient.ts';
import MedIcon from '../MedIcon';
import StatusBadge from '../StatusBadge';
import Time from '../Time';
import { Button } from '../ui/button';
import { StatusIndicator } from './StatusIndicator';

const ListItem = ({ request }: { request: IRequest; index: number }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const archive = async () => {
    axiosClient
      .delete(`/v1/requests/${request.id}`)
      .then((response) => {
        if (response.status === 200) {
          queryClient.setQueryData([`v1/requests`], (oldData: any) => {
            return oldData.filter((item: IRequest) => item.id !== request.id);
          });
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const duplicate = () => {
    axiosClient.post(`/v1/requests?last_request_id=${request.id}&exclude_invalid_pharmacies=true`).then((response) => {
      posthog.capture('order_again');
      setTimeout(() => navigate(`/requests/${response.data.id}`), 100);
    });
  };

  const getStatusPriority = (
    tasks: ITask[],
  ): 'in_stock' | 'out_of_stock' | 'callback' | 'scheduled' | 'pending' | 'pending_approval' | string | null => {
    if (tasks.some((task) => task.stock_status === 'in_stock')) {
      return 'in_stock';
    }

    if (tasks.some((task) => task.stock_status === 'out_of_stock')) {
      return 'out_of_stock';
    }

    return '';
  };

  return (
    <>
      <div className="flex flex-col w-full gap-5 p-3 bg-white border border-gray-300 rounded-xl ">
        <div className="flex flex-row items-center justify-between ">
          <div className="grow">
            <Link
              to={`/requests/${request.id}`}
              className="text-xl font-semibold text-purple-500 uppercase cursor-pointer"
            >
              #{request.id.substring(0, 6)}
            </Link>
            <div className="text-sm font-medium text-slate-500">
              <Time>{request.created_at}</Time>
            </div>
          </div>

          <div className="">
            <StatusBadge statusDetails={request.status_details} />
          </div>
        </div>

        {request.medications.length > 0 && (
          <div className="border border-gray-300 divide-y rounded-lg">
            {request.medications.map((medication, index) => {
              const tasks = request.tasks.filter((task: ITask) => task.medication_id === medication.id);
              const tasksBestStatus = getStatusPriority(tasks);

              return (
                <div
                  key={`order-${index}`}
                  className="flex flex-row items-center justify-between gap-2 p-2 text-gray-900"
                >
                  <div className="flex flex-row items-center gap-3">
                    <MedIcon medication={medication} />
                    <div className="flex flex-col gap-1 text-base text-gray-900 ">
                      <div className="font-semibold">{medication.medication}</div>
                      <div className="text-xs text-gray-500 truncate line-clamp-1">
                        {medication.variant}{' '}
                        {medication.label && (
                          <>
                            for{' '}
                            <span className="text-gray-900 underline decoration-dotted decoration-purple-800">
                              {' '}
                              {medication.label}
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="text-sm text-gray-500 ">
                    {request.status == 'complete' && tasksBestStatus && (
                      <Link to={`/requests/${request.id}`}>
                        <StatusIndicator status={tasksBestStatus} isOrderLevel={true} />
                      </Link>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        )}

        <div className="flex justify-between text-sm text-right text-gray-700 text-pretty">
          <div className="flex items-center gap-2">
            <AlertDialog>
              <AlertDialogTrigger asChild>
                <Button
                  variant="outline"
                  className="text-xs text-red-600 rounded-full hover:text-red-600 "
                  size={'icon'}
                >
                  <TrashIcon className="h-4 text-red-600" />
                </Button>
              </AlertDialogTrigger>
              <AlertDialogContent>
                <AlertDialogHeader>
                  <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
                  <AlertDialogDescription>
                    This will archive this order. You will not be able to undo this action.
                  </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <AlertDialogCancel>Cancel</AlertDialogCancel>
                  <AlertDialogAction onClick={archive}>Remove</AlertDialogAction>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
            <AlertDialog>
              <AlertDialogTrigger asChild>
                <Button variant="outline" className="gap-1 rounded-full" size={'sm'}>
                  <Redo2 className="h-4 text-purple-900" />
                  Order Again
                </Button>
              </AlertDialogTrigger>
              <AlertDialogContent>
                <AlertDialogHeader>
                  <AlertDialogTitle>Are you ready to order again?</AlertDialogTitle>
                  <AlertDialogDescription>
                    We will duplicate this order and you can make any changes before placing the new order. We transfer
                    all medications, pharmacies, notes, and your location.
                  </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <AlertDialogCancel>Cancel</AlertDialogCancel>
                  <AlertDialogAction onClick={duplicate}>Order Again</AlertDialogAction>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          </div>

          <div className="flex items-center gap-2">
            <Button className="gap-2 rounded-full" size={'sm'} asChild>
              <Link to={`/requests/${request.id}`}>View Order</Link>
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListItem;
