import {
  GoAlert,
  GoCheck,
  GoClock,
  GoCreditCard,
  GoEye,
  GoIssueReopened,
  GoThumbsdown,
  GoVerified,
} from 'react-icons/go';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { cn } from '@/utils/utils';
import { Badge } from '../ui/badge';
import { Button } from '../ui/button';

interface StatusIndicatorProps {
  status:
    | 'pending'
    | 'in_stock'
    | 'out_of_stock'
    | 'callback'
    | 'scheduled'
    | 'pending_approval'
    | 'invalid'
    | string
    | null;
  className?: string;
  isPharmacyLevel?: boolean;
  isOrderLevel?: boolean;
}

interface StatusConfig {
  label: string;
  icon?: JSX.Element;
  className: string;
  hoverText: {
    medication: string;
    pharmacy: string;
    order: string;
  };
}

const statusConfig = {
  pending: {
    label: 'In Progress',
    icon: <GoIssueReopened />,
    className:
      'bg-[#FDF6EA] text-[#EFA22F] hover:bg-[#FFEFD4] hover:text[#F0940B] border border-[#F3BB67] hover:border-[#F3AC41]',
    hoverText: {
      medication: "We're in the process of checking the availability of your medication.",
      pharmacy: "We're in the process of checking the availability of your medication.",
      order: "We're in the process of checking the availability of your medication.",
    },
  },
  scheduled: {
    label: 'Scheduled',
    icon: <GoClock className="w-4 h-4" />,
    className:
      'bg-[#EEEEFF] text-[#3259E3] hover:bg-[#D6D6FF] hover:text[#F0940B] border border-[#3259E3] hover:border-[#113AC8]',
    hoverText: {
      medication: 'This call has been scheduled.',
      pharmacy: 'This call has been scheduled.',
      order: 'This call has been scheduled.',
    },
  },
  pending_approval: {
    label: 'Pending',
    icon: <GoVerified className="w-4 h-4" />,
    className:
      'bg-[#EEEEFF] text-[#3259E3] hover:bg-[#D6D6FF] hover:text[#F0940B] border border-[#3259E3] hover:border-[#113AC8]',
    hoverText: {
      medication: "We're in the process of reviewing the order.",
      pharmacy: "We're in the process of reviewing the order.",
      order: "We're in the process of reviewing the order.",
    },
  },
  in_stock: {
    label: 'In Stock',
    icon: <GoCheck className="w-4 h-4" />,
    className:
      'bg-[#E9F8F1] text-[#27B973] hover:bg-[#D4F9E8] hover:text[#F0940B] border border-green-500 hover:border-[#269A62]',
    hoverText: {
      medication: 'This medication was found to be in stock at this pharmacy!',
      pharmacy: 'One or more of your requested medications are in stock at this pharmacy!',
      order: 'We found your medication in stock at one or more of your pharmacies!',
    },
  },
  out_of_stock: {
    label: 'Out of Stock',
    icon: <GoThumbsdown className="w-4 h-4" />,
    className:
      'bg-[#FFEFEE] text-[#E33B32] hover:bg-[#FFE1DF] hover:text[#F0940B] border border-[#E33B32] hover:border-[#C33028]',
    hoverText: {
      medication: 'This medication is currently out of stock at this pharmacy.',
      pharmacy: 'One or more of your medications are out of stock at this pharmacy.',
      order: "Unfortunately, we couldn't find your medication in stock at any of the checked pharmacies.",
    },
  },
  callback: {
    label: 'Follow up',
    icon: <GoEye className="w-4 h-4" />,
    className:
      'bg-[#FDF6EA] text-[#EFA22F] hover:bg-[#FFEFD4] hover:text[#F0940B] border border-[#F3BB67] hover:border-[#F3AC41]',
    hoverText: {
      medication: 'We had some difficulty determining if this medication is available. We will call the pharmacy back.',
      pharmacy: 'We need to call this pharmacy back to confirm availability of one or more medications.',
      order: 'We need to follow up with one or more pharmacies to confirm the availability of your medication.',
    },
  },
  invalid: {
    label: 'Invalid',
    icon: <GoAlert className="w-4 h-4" />,
    className:
      'bg-[#FFEFEE] text-[#E33B32] hover:bg-[#FFE1DF] hover:text[#F0940B] border border-[#E33B32] hover:border-[#C33028]',
    hoverText: {
      medication: "There was an issue with this call. We'll try to resolve this for you.",
      pharmacy:
        "There was an issue with this pharmacy. We'll try to resolve this for you. If not credit's are on the way!",
      order:
        "There was an issue with one or more of the pharmacies we tried to contact. We'll try to resolve this for you.",
    },
  },
  quote: {
    label: 'Pending Payment',
    icon: <GoCreditCard className="w-4 h-4 mr-0.5" />,
    className: 'bg-red-100 text-red-800 hover:bg-red-200 hover:text-red-800 border border-red-400 hover:border-red-300',
    hoverText: {
      medication: 'This order has not been submitted yet. Please complete the payment process.',
      pharmacy: 'This order has not been submitted yet. Please complete the payment process.',
      order: 'This order has not been submitted yet. Please complete the payment process.',
    },
  },
} as Record<string, StatusConfig>;

export function StatusIndicator({
  status,
  className = '',
  isPharmacyLevel = false,
  isOrderLevel = false,
}: StatusIndicatorProps) {
  if (status === null) {
    status = 'invalid';
  }
  if (!statusConfig[status]) {
    return null;
  }

  const { label, className: statusClassName, hoverText } = statusConfig[status];
  let tooltipText;
  if (isOrderLevel) {
    tooltipText = hoverText.order;
  } else if (isPharmacyLevel) {
    tooltipText = hoverText.pharmacy;
  } else {
    tooltipText = hoverText.medication;
  }

  const icon = statusConfig[status].icon || Badge;

  return (
    <div className="cursor-pointer">
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              className={cn(
                ` flex items-center px-2 h-8 text-[11px] gap-1 font-normal rounded-md  text-nowrap flex-nowrap`,
                statusClassName,
                className,
              )}
            >
              {(icon && <span className="">{icon as any}</span>) || null}
              <div className="text-nowrap">{isOrderLevel ? getOrderLevelLabel(status) : label}</div>
            </Button>
          </TooltipTrigger>
          <TooltipContent>{tooltipText}</TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </div>
  );
}

function getOrderLevelLabel(status: string): string {
  switch (status) {
    case 'scheduled':
      return 'Scheduled';
    case 'in_stock':
      return 'In Stock';
    case 'out_of_stock':
      return 'Out of Stock';
    case 'pending':
      return 'In Progress';
    case 'pending_approval':
      return 'Pending Approval';
    case 'callback':
      return 'Following Up';
    case 'invalid':
      return 'Invalid';
    case 'quote':
      return 'Pending Payment';
    default:
      return status;
  }
}
