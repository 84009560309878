import * as React from 'react';
import { OTPInput, OTPInputContext } from 'input-otp';
import { Dot } from 'lucide-react';
import { cn } from '@/utils/utils';

const InputOTP = React.forwardRef<React.ElementRef<typeof OTPInput>, React.ComponentPropsWithoutRef<typeof OTPInput>>(
  ({ className, containerClassName, ...props }, ref) => (
    <OTPInput
      ref={ref}
      containerClassName={cn('flex items-center gap-2 has-[:disabled]:opacity-50', containerClassName)}
      className={cn('disabled:cursor-not-allowed', className)}
      {...props}
    />
  ),
);
InputOTP.displayName = 'InputOTP';

interface InputOTPGroupProps extends React.ComponentPropsWithoutRef<'div'> {
  inputMode?: React.HTMLAttributes<HTMLInputElement>['inputMode'];
}

const InputOTPGroup = React.forwardRef<React.ElementRef<'div'>, InputOTPGroupProps>(
  ({ className, inputMode, ...props }, ref) => (
    <div ref={ref} className={cn('flex items-center', className)} {...props} data-input-mode={inputMode} />
  ),
);
InputOTPGroup.displayName = 'InputOTPGroup';

interface InputOTPSlotProps extends React.ComponentPropsWithoutRef<'div'> {
  index: number;
}

const InputOTPSlot = React.forwardRef<React.ElementRef<'div'>, InputOTPSlotProps>(
  ({ index, className, ...props }, ref) => {
    const inputOTPContext = React.useContext(OTPInputContext);
    const { char, hasFakeCaret, isActive } = inputOTPContext.slots[index];

    return (
      <div
        ref={ref}
        className={cn(
          'relative flex h-10 w-10 items-center justify-center border-y border-r border-gray-300 text-sm transition-all first:rounded-l-md first:border-l last:rounded-r-md',
          isActive && 'z-10 ring-1 ring-ring ring-purple-700',
          className,
        )}
        {...props}
      >
        {char}
        {hasFakeCaret && (
          <div className="absolute inset-0 flex items-center justify-center pointer-events-none">
            <div className="w-px h-4 duration-1000 bg-white animate-caret-blink" />
          </div>
        )}
      </div>
    );
  },
);
InputOTPSlot.displayName = 'InputOTPSlot';

const InputOTPSeparator = React.forwardRef<React.ElementRef<'div'>, React.ComponentPropsWithoutRef<'div'>>(
  ({ ...props }, ref) => (
    <div ref={ref} role="separator" {...props}>
      <Dot />
    </div>
  ),
);
InputOTPSeparator.displayName = 'InputOTPSeparator';

export { InputOTP, InputOTPGroup, InputOTPSlot, InputOTPSeparator };
