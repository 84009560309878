import { createContext, useContext, useEffect, useState } from 'react';


// Define the types for your authentication context
type AuthContextType = boolean | null;
type SetAuthContextType = React.Dispatch<React.SetStateAction<AuthContextType>>;

const AuthContext = createContext<AuthContextType>(null);
const SetAuthContext = createContext<SetAuthContextType>(() => {});

const getSession = () => {
  const data = localStorage.getItem('Needle');
  if (data) {
    return JSON.parse(data).sessionId;
  }
  return '';
};

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [value, setAuth] = useState<AuthContextType>(null);
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (!loaded) {
      const sessionId = getSession();
      if (sessionId !== '' && sessionId !== null && sessionId !== undefined) {
        setAuth(true);
      } else {
        setAuth(false);
      }
      setLoaded(true);
    }
  }, [loaded, setLoaded, setAuth]);

  if (!loaded) {
    return null;
  }
  return (
    <AuthContext.Provider value={value}>
      <SetAuthContext.Provider value={setAuth}>{children}</SetAuthContext.Provider>
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const auth = useContext(AuthContext);
  if (auth === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return auth;
};
