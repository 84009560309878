import { IPharmacy, IRequest } from '@/types.ts';
import { getUrl } from '@/utils/api.ts';

function usePharmacyFinder(request: IRequest) {
  const query = getUrl(
    `v2/request_pharmacies/${request.id}/finder`,
    {
      enabled: request.id && request.location?.address != null,
    },
  );

  return {
    ...query,
    data: query.data as IPharmacy[],
  };
}

export default usePharmacyFinder;
