import { IUser } from '@/types.ts';
import { getUrl } from '@/utils/api';

function useMe() {
  const query = getUrl(`v1/users/me`);
  return {
    ...query,
    data: query.data as IUser,
  };
}

export default useMe;
