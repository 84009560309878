
import useRequestProcessor from '@/hooks/useRequestProcessor';
import axiosClient from '@/utils/axiosClient';

export function getUrl(url: string, options?: any, key?: string) {
  const { query } = useRequestProcessor();

  return query(
    key ? key : url,
    () => {
      return axiosClient.get(`/${url}`).then((response: any) => {
        return response.data;
      });
    },
    options,
  );
}
