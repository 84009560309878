import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { DollarSign } from 'lucide-react';
import Modal from '@/components/Modals/Modal.tsx';
import CreditPackages from '@/components/Requests/CreditPackages';
import SubscriptionCTA from '@/components/Requests/SubscriptionCTA';
import useMe from '@/hooks/useMe';

interface CreditsModalProps {
  requestId?: string;
}

const CreditsModal = NiceModal.create(({ requestId }: CreditsModalProps) => {
  const modal = useModal('credits-modal');
  const currentUser = useMe();

  return (
    <Modal
      modal={modal}
      title={'Purchase Credits'}
      description={'Select the number of credits you would like to purchase'}
      wide
    >
      {currentUser.isLoading || !currentUser?.data ? (
        <div className="flex items-center justify-center h-64">
          <div className="text-center">
            <div className="text-lg font-semibold">Loading...</div>
            <div className="text-sm">Please wait while we load your account information.</div>
          </div>
        </div>
      ) : (
        <>
          {!currentUser?.data?.membership_is_active && (
            <div className="flex items-center gap-2 p-2 mb-8 bg-gray-200 border border-gray-300 rounded-lg">
              <div className="">
                <DollarSign className="w-12 h-12 text-brand-purple" />
              </div>
              <div className="">
                <div className="mb-2 font-semibold text-brand-purple">What are credits?</div>
                <div className="text-sm text-pretty">
                  We are introducing credits as a way to simplify our pricing and make it easier for you.
                </div>
                <div className="mt-2 text-sm text-pretty">
                  Each call costs 1 credit. If we make multiple calls to a pharmacy it is counted as 1 credit. If we are
                  unable to make a call to a pharmacy, we will refund the credit to your account. Credits do not expire
                  and can be used at any time.
                </div>
              </div>
            </div>
          )}

          <CreditPackages requestId={requestId as string} />

          {!currentUser?.data?.membership_is_active && (
            <div className="mt-8">
              <SubscriptionCTA requestId={requestId as string} />
            </div>
          )}
        </>
      )}
    </Modal>
  );
});

export default CreditsModal;
