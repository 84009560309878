import * as React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from '@/utils/utils';

const badgeVariants = cva(
  'inline-flex items-center rounded-full border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2',
  {
    variants: {
      variant: {
        default: 'border-transparent bg-primary text-primary-foreground hover:bg-primary/80',
        secondary: 'border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80',
        pending: 'border-primary text-brand-dark-blue bg-white',
        in_stock: 'text-[#27B973] bg-[#E9F8F1]',
        out_of_stock: 'text-[#E33B32] bg-[#FFEFEE] hover:bg-[#FFEFEE]/90 hover:text-[#F86767]',
        complete: 'border-transparent bg-green-100 text-green-600',
        processing: 'border-transparent bg-blue-100 text-blue-600',
        queued: 'border-transparent bg-yellow-100 text-yellow-600',
        destructive: 'border-transparent bg-destructive text-destructive-foreground hover:bg-destructive/80',
        outline: 'text-foreground',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
);

export interface BadgeProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, ...props }: BadgeProps) {
  return <div className={cn(badgeVariants({ variant }), className)} {...props} />;
}

export { Badge, badgeVariants };
