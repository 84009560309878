import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import Loading from '@/components/Loading.tsx';
import axiosClient from '@/utils/axiosClient.ts';

const RequestCreator = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const medicationId = searchParams.get('medication_id') || '';

  const payload = {
    medication_id: '',
  };

  if (medicationId) {
    payload.medication_id = medicationId;
  }

  useEffect(() => {
    axiosClient.post(`/v1/requests`, payload).then((response) => {
      navigate(`/requests/${response.data.id}`);
    });
  }, []);

  return <Loading />;
};

export default RequestCreator;
