import { Building, Clock, Percent, Phone } from 'lucide-react';
import { IRequest } from '@/types';
import Section from '../Section';

interface RequestOutcomeProps {
  request: IRequest;
}

const RequestOutcome = ({ request }: RequestOutcomeProps) => {
  const tasks = request.tasks;
  const calls = request.tasks.map((task) => task.calls).flat();

  if (tasks.length === 0) {
    return null;
  }

  const inStock = tasks.filter((task) => task.stock_status === 'in_stock');
  const inStockPercentage = (inStock.length / tasks.length) * 100;

  const totalCallTime =
    calls.reduce((acc, call) => {
      const duration = parseFloat(call?.final_duration as any);
      return acc + duration;
    }, 0) || 0;

  const formatTime = (seconds: number) => {
    if (isNaN(seconds)) return '0s';

    const totalSeconds = Math.floor(seconds);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const remainingSeconds = totalSeconds % 60;

    if (hours > 0) {
      return `${hours}h ${minutes}m`;
    } else if (minutes > 0) {
      return `${minutes}m ${remainingSeconds}s`;
    } else {
      return `${remainingSeconds}s`;
    }
  };

  return (
    <>
      <Section className="bg-purple-100">
        <div className="grid grid-cols-2 gap-4 p-2 mt-2 sm:p-4 sm:grid-cols-4">
          <div className="flex flex-col gap-2 p-4 bg-white rounded-lg ">
            <div className="flex items-center justify-between ">
              <div className="text-sm font-semibold text-gray-500 grow">Time Saved</div>
              <div>
                <Clock className="h-5 text-blue-500" />
              </div>
            </div>
            <div className="text-2xl font-bold">{formatTime(totalCallTime)}</div>
          </div>
          <div className="flex flex-col gap-2 p-4 bg-white rounded-lg ">
            <div className="flex items-center justify-between ">
              <div className="text-sm font-semibold text-gray-500 grow">Pharmacies</div>
              <div className="">
                <Building className="h-5 text-green-500" />
              </div>
            </div>
            <div className="text-2xl font-bold">{request.pharmacies.length}</div>
          </div>
          <div className="flex flex-col gap-2 p-4 bg-white rounded-lg ">
            <div className="flex items-center justify-between ">
              <div className="text-sm font-semibold text-gray-500 grow">Total Calls</div>
              <div className="">
                <Phone className="h-5 text-yellow-500" />
              </div>
            </div>
            <div className="text-2xl font-bold">{calls.length}</div>
          </div>
          <div className="flex flex-col gap-2 p-4 bg-white rounded-lg ">
            <div className="flex items-center justify-between ">
              <div className="text-sm font-semibold text-gray-500 grow">Stock Rate</div>
              <div className="">
                <Percent className="h-5 text-purple-500" />
              </div>
            </div>
            <div className="text-2xl font-bold">
              {inStockPercentage.toFixed(2)}%
              <div className="text-xs text-gray-500">
                ({inStock.length}/{tasks.length})
              </div>
            </div>
          </div>
        </div>
      </Section>
    </>
  );
};

export default RequestOutcome;
