import { useEffect, useState } from 'react';
import { useModal } from '@ebay/nice-modal-react';
import { ArrowLeft, Edit2Icon, Plus, PlusIcon, Search, X } from 'lucide-react';
import { Link } from 'react-router-dom';
import MedIcon from '@/components/MedIcon';
import Section from '@/components/Section';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import useMedications from '@/hooks/useMedications';

const Medications = () => {
  const medications = useMedications();
  const medicationModal = useModal('medication-modal');
  const [search, setSearch] = useState('');

  const sortedMedications = medications.data?.sort((a, b) => a.medication.localeCompare(b.medication)) || [];

  const filteredMedications = sortedMedications.filter((medication) => {
    return (
      medication.medication.toLowerCase().includes(search.toLowerCase()) ||
      medication.dosage?.toLowerCase().includes(search.toLowerCase()) ||
      medication.dosage_form?.toLowerCase().includes(search.toLowerCase()) ||
      medication.label?.toLowerCase().includes(search.toLowerCase()) ||
      medication.note?.toLowerCase().includes(search.toLowerCase()) ||
      medication.preference?.toLowerCase().includes(search.toLowerCase()) ||
      medication.quantity?.toLowerCase().includes(search.toLowerCase()) ||
      medication.variant?.toLowerCase().includes(search.toLowerCase())
    );
  });

  useEffect(() => {
    if (!medications.isLoading && medications.data?.length === 0) {
      medicationModal.show({ request: null, medications: medications.data });
    }
  }, [medications]);

  return (
    <>
      <div className="flex items-center justify-start gap-1 mt-4 mb-6 sm:mt-2 ">
        <div className="mr-2 leading-snug text-gray-600 hover:text-violet-600">
          <Link to="/">
            <ArrowLeft />
          </Link>
        </div>
        <div className="font-medium leading-snug text-violet-600">Medication List</div>
      </div>

      <div className="flex flex-col justify-between gap-4 mb-8 md:flex-row sm:gap-8">
        <div className="flex flex-col">
          <div className="text-3xl font-bold leading-8 text-neutral-800 sm:text-2xl text-pretty">
            All of your medications in one place!
          </div>
          <div className="max-w-sm text-sm font-normal leading-normal text-gray-500 md:max-w-none text-pretty">
            Organize your medication list and keep track of your prescriptions with Needle.
          </div>
        </div>
        <div className="flex items-center justify-end">
          <Button
            className="items-center gap-2 rounded-full"
            onClick={() =>
              medicationModal.show({
                medications: sortedMedications,
                defaultPath: 'newMedication',
              })
            }
          >
            <PlusIcon className="w-6 h-6" />
            Add Medication
          </Button>
        </div>
      </div>

      <div className="inline-flex items-center justify-start w-full gap-1 py-1 pl-4 pr-2 bg-white border shadow-sm rounded-2xl border-neutral-200">
        <Search className="w-5 h-5 text-neutral-500" />
        <Input
          type="text"
          placeholder="Search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          className="w-full text-sm font-medium placeholder-gray-500 bg-transparent border-none focus-visible:ring-none focus-visible:ring-0 focus-visible:ring-white focus-visible:outline-none focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50"
        />
        {search.length > 0 && (
          <Button variant={'ghost'} onClick={() => setSearch('')}>
            <X className="w-4 h-4 mr-1" />
          </Button>
        )}
      </div>

      <Section title="Medications" className="mt-8">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="w-72">Name</TableHead>
              <TableHead>Label</TableHead>
              <TableHead>Variant</TableHead>
              <TableHead>Dosage</TableHead>
              <TableHead>QTY</TableHead>
              <TableHead></TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {filteredMedications.map((medication) => (
              <TableRow>
                <TableCell className="font-medium">
                  <div className="flex flex-row items-center gap-4 ">
                    <MedIcon medication={medication} />
                    <div className="font-semibold">{medication.medication}</div>
                  </div>
                </TableCell>
                <TableCell>{medication.label}</TableCell>
                <TableCell>{medication.variant}</TableCell>
                <TableCell>{medication.dosage}</TableCell>
                <TableCell>{medication.quantity}</TableCell>
                <TableCell>
                  <div className="flex flex-row justify-end">
                    <Button
                      variant="outline"
                      className="h-8 gap-2 border-gray-300 rounded-full"
                      onClick={() =>
                        medicationModal.show({
                          request: null,
                          medication: medication,
                          medications: medications.data,
                        })
                      }
                    >
                      <Edit2Icon className="w-4 h-4 mr-1" />
                      Edit
                    </Button>
                    <Button className="h-8 gap-1 ml-2 border-gray-300 rounded-full" asChild>
                      <Link to={`/requests/new?medication_id=${medication.id}`}>
                        <Plus className="w-4 h-4" />
                        <span className="hidden md:block">Create</span> Order
                      </Link>
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {!filteredMedications.length && search.length < 0 && (
          <div className="w-full py-3 text-center text-gray-500 ">You have not added any medications</div>
        )}
        {!filteredMedications.length && search.length > 0 && (
          <div className="w-full py-3 text-center text-gray-500 ">No medications</div>
        )}
      </Section>
    </>
  );
};

export default Medications;
