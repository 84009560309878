const processingOptions = [
  {
    label: '1-2 Business Days',
    description: 'We will contact the pharmacies during normal business hours on a first come first serve basis.',
    price: 0,
    membership_price: 0,
  },
  {
    label: 'Same Day Expedited',
    description:
      'To the top of the queue you go. Same day cutoff time is past 3pm local time, otherwise next business day.',
    price: 5,
    membership_price: 0,
  },
  {
    label: 'Weekend Expedited',
    description: 'Need your medication on the weekend? We will contact the pharmacies on Saturday and Sunday.',
    price: 25,
    membership_price: 20,
  },
];

export default processingOptions;
