import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist({ key: 'Needle' });

const state = {
  sidebarOpen: atom({
    key: 'sidebarOpen',
    default: false,
    effects_UNSTABLE: [persistAtom],
  }),
  sessionId: atom({
    key: 'sessionId',
    default: null,
    effects_UNSTABLE: [persistAtom],
  }),
  currentUser: atom({
    key: 'currentUser',
    default: {},
    effects_UNSTABLE: [persistAtom],
  }),
};

export default state;
