import { Label } from '@/components/ui/label';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';

interface VariantProps {
  prescription: any;
  update: (key: string, value: string) => void;
  drug: any;
}

const Variant = ({ prescription, update, drug }: VariantProps) => {
  const handleChange = (variant: string) => {
    update('variant', variant);
  };

  return (
    <>
      <Label>What type of {drug.name} is this written for?</Label>
      <Select onValueChange={handleChange} value={prescription.variant}>
        <SelectTrigger>
          <SelectValue placeholder={`What type of ${drug.name} is this written for?`} />
        </SelectTrigger>
        <SelectContent>
          {drug.variants?.map((variant: any) => (
            <SelectItem key={variant.name} value={variant.name}>
              {variant.name}
              {variant.short && (
                <>
                  <span>({variant.short})</span>
                </>
              )}
              {variant.dosageForm && (
                <>
                  <span className="ml-1 text-neutral-500">- {variant.dosageForm}</span>
                </>
              )}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </>
  );
};

export default Variant;
