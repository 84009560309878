import { useEffect } from 'react';
import { useCopyToClipboard } from '@uidotdev/usehooks';
import useMe from '@/hooks/useMe';
import Section from '../Section';
import { Button } from '../ui/button';

const ReferralCard = () => {
  const me = useMe();
  const [copiedText, copyToClipboard] = useCopyToClipboard();
  const hasCopiedText = Boolean(copiedText);

  useEffect(() => {
    if (hasCopiedText) {
      const timer = setTimeout(() => {
        copyToClipboard('');
      }, 1500);
      return () => clearTimeout(timer);
    }
  }, [hasCopiedText]);

  return (
    <>
      <Section title={'Invite a friend!'}>
        <div className="p-4 -mt-4">
          <div className="text-sm text-gray-700">
            Invite a friend and get 50% off on your next order. On top of that, you can get prizes when your friends
            create and purchase an order.
          </div>
          <div className="flex flex-row items-center gap-2 mt-4">
            <div className="px-4 py-2 font-bold text-gray-900 border border-gray-400 border-dashed rounded-sm w-fit">
              {me.data?.referral_code}
            </div>
            <Button
              onClick={() => copyToClipboard(me.data?.referral_code)}
              className="font-semibold bg-brand-green text-brand-dark-blue hover:text-white"
            >
              {hasCopiedText ? <>Copied!</> : <>Copy Code</>}
            </Button>
          </div>
        </div>
      </Section>
    </>
  );
};

export default ReferralCard;
