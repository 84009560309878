import NiceModal from '@ebay/nice-modal-react';
import LocationModal from '@/components/Location/LocationInput.tsx';
import MedicationModal from '@/components/Modals/Medications/MedicationModal.tsx';
import MedicationPhotoModal from '@/components/Modals/Medications/MedicationPhotoModal.tsx';
import CreditsModal from './Credits/CreditsModal';
import SliderCreditsModal from './Credits/SliderCreditsModal';
import MedicationResultsModal from './MedicationResults/MedicationResultsModal';

NiceModal.register('medication-modal', MedicationModal);
NiceModal.register('medication-photo-modal', MedicationPhotoModal);
NiceModal.register('location-modal', LocationModal);
NiceModal.register('credits-modal', CreditsModal);
NiceModal.register('slider-credits-modal', SliderCreditsModal);
NiceModal.register('medication-results-modal', MedicationResultsModal);

const ModalRegistration = () => {
  return <></>;
};

export default ModalRegistration;
