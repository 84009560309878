import React, { useState, useEffect, useMemo } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { loadStripe } from '@stripe/stripe-js';
import { DollarSign } from 'lucide-react';
import { IoCaretBackSharp, IoCaretForwardSharp } from 'react-icons/io5';
import Modal from '@/components/Modals/Modal';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import useMe from '@/hooks/useMe';
import axiosClient from '@/utils/axiosClient';
import { cn } from '@/utils/utils';

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);

interface SliderCreditsModalProps {
  requestId: string;
  requiredCredits: number;
}

const SliderCreditsModal = NiceModal.create(({ requestId, requiredCredits }: SliderCreditsModalProps) => {
  const modal = useModal('slider-credits-modal');
  const currentUser = useMe();
  const [selectedCredits, setSelectedCredits] = useState(requiredCredits);
  const [startIndex, setStartIndex] = useState(0);
  const [price, setPrice] = useState(0);
  const [showDisclaimer, setShowDisclaimer] = useState(false);

  const maxCredits = requiredCredits + 500;

  useEffect(() => {
    setPrice(selectedCredits * 1);
  }, [selectedCredits]);

  const creditOptions = useMemo(() => {
    const options = [];
    for (let i = 0; i < 5; i++) {
      const value = requiredCredits + (startIndex + i) * 5;
      if (value <= maxCredits) {
        options.push(value);
      }
    }
    return options;
  }, [requiredCredits, startIndex, maxCredits]);

  const handlePurchase = () => {
    setShowDisclaimer(true);
  };

  const handleConfirmPurchase = async () => {
    try {
      const response = await axiosClient.post('/v1/credits', {
        credits: selectedCredits,
        request_id: requestId,
        submit_order: 'true',
      });
      if (response?.data?.stripe_session_id) {
        const stripe = await stripePromise;
        if (stripe) {
          await stripe.redirectToCheckout({
            sessionId: response.data.stripe_session_id,
          });
        }
      }
    } catch (error) {
      console.error('Error creating checkout session:', error);
    }
  };

  const handleIncrement = () => {
    if (selectedCredits < maxCredits) {
      setSelectedCredits((prev) => prev + 5);
      if (selectedCredits === creditOptions[creditOptions.length - 1]) {
        setStartIndex((prev) => prev + 1);
      }
    }
  };

  const handleDecrement = () => {
    if (selectedCredits > requiredCredits) {
      setSelectedCredits((prev) => prev - 5);
      if (selectedCredits === creditOptions[0]) {
        setStartIndex((prev) => Math.max(0, prev - 1));
      }
    }
  };

  return (
    <Modal
      modal={modal}
      title="Purchase Credits"
      description="Select the number of credits you would like to purchase"
      wide
    >
      {currentUser.isLoading || !currentUser?.data ? (
        <div className="flex items-center justify-center h-64">
          <div className="text-center">
            <div className="text-lg font-semibold">Loading...</div>
            <div className="text-sm">Please wait while we load your account information.</div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center space-y-8">
          <div className="flex items-center justify-center w-full gap-4">
            <button
              onClick={handleDecrement}
              className={cn(
                'text-3xl transition-colors p-2 rounded-full',
                selectedCredits > requiredCredits
                  ? 'text-brand-purple hover:bg-gray-100'
                  : 'text-gray-300 cursor-not-allowed',
              )}
              disabled={selectedCredits <= requiredCredits}
            >
              <IoCaretBackSharp />
            </button>
            <div className="flex items-center gap-4">
              {creditOptions.map((credits) => (
                <button
                  key={credits}
                  onClick={() => setSelectedCredits(credits)}
                  className={cn(
                    'w-16 h-16 rounded-full flex items-center justify-center text-xl font-bold transition-all',
                    selectedCredits === credits
                      ? 'bg-brand-purple text-white scale-110 shadow-lg'
                      : 'bg-gray-100 text-gray-600 hover:bg-gray-200',
                  )}
                >
                  {credits}
                </button>
              ))}
            </div>
            <button
              onClick={handleIncrement}
              className={cn(
                'text-3xl transition-colors p-2 rounded-full',
                selectedCredits < maxCredits
                  ? 'text-brand-purple hover:bg-gray-100'
                  : 'text-gray-300 cursor-not-allowed',
              )}
              disabled={selectedCredits >= maxCredits}
            >
              <IoCaretForwardSharp />
            </button>
          </div>
          <div className="text-center">
            <div className="text-5xl font-bold text-brand-purple">{selectedCredits} Credits</div>
            <div className="mt-2 text-2xl font-semibold">${price.toFixed(2)}</div>
          </div>
          <div className="w-full max-w-md p-4 bg-gray-100 rounded-lg">
            <div className="flex items-start gap-3">
              <DollarSign className="w-6 h-6 mt-1 text-brand-purple" />
              <div>
                <div className="font-semibold text-brand-purple">What are credits?</div>
                <div className="mt-1 text-sm">
                  Credits are used to pay for pharmacy calls. Each call costs 1 credit, and unused credits are refunded
                  to your account. Credits don't expire and can be used anytime.
                </div>
              </div>
            </div>
          </div>
          <button
            onClick={handlePurchase}
            className="w-full max-w-md px-8 py-3 text-lg font-semibold text-white transition-colors rounded-full bg-brand-purple hover:bg-purple-700"
          >
            Purchase Credits
          </button>
        </div>
      )}

      <AlertDialog open={showDisclaimer} onOpenChange={setShowDisclaimer}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Confirm Credit Purchase and Order Submission</AlertDialogTitle>
            <AlertDialogDescription>
              <div className="flex flex-col gap-4 mt-4 font-semibold">
                <div>
                  You are about to purchase {selectedCredits} credits for ${price.toFixed(2)}.
                </div>
                <div>
                  After this purchase, you order will be submitted, and the <b>TOTAL AMOUNT</b> of credits based on the
                  number of pharmacies you've selected will be deducted from your account to submit your order.
                </div>
                <div>
                  Needle will start contacting the pharmacies based on your processing time selection immediately after
                  the order is submitted.
                </div>
                <div>
                  Up until we begin searching, you may make changes to your search. You will be able to expand this
                  search after we finish. Once we finish searching, we will notify you via email.
                </div>
                <div>Any unused or remaining credit will be returned to your account after searching is complete.</div>
                <div>
                  By clicking "Continue" you agree to purchase the credits, submit your order, and accept our{' '}
                  <a
                    href="https://findneedle.co/terms"
                    className="underline text-primary"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms of Service
                  </a>{' '}
                  and{' '}
                  <a
                    href="https://findneedle.co/privacy"
                    className="underline text-primary"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>
                  .
                </div>
              </div>
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={handleConfirmPurchase}>Continue</AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </Modal>
  );
});

export default SliderCreditsModal;
