import { useState, useRef, useEffect } from 'react';
import { MdLocationPin, MdHelp } from 'react-icons/md';
import LocationInput from '@/components/Location/LocationInput.tsx';
import LocationMap from '@/components/Location/LocationMap.tsx';
import { IRequest } from '@/types.ts';
import Section from '../Section';

interface LocationProps {
  request: IRequest;
}

const Location = ({ request }: LocationProps) => {
  const [showHelp, setShowHelp] = useState(false);
  const helpRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        helpRef.current &&
        !helpRef.current.contains(event.target as Node) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        setShowHelp(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Section title="Location" className="pt-1">
      <div className="relative w-full pt-1">
        <div className="absolute w-full top-2 z-10">
          <div className="px-2 mx-auto">
            <div className="flex items-center w-full gap-1 px-3 py-2 bg-white rounded-md shadow-md">
              <MdLocationPin className="w-6 h-6 text-brand-purple" />
              <LocationInput request={request} />
              <button
                ref={buttonRef}
                onClick={() => setShowHelp(!showHelp)}
                className="flex-shrink-0"
                aria-label="Help"
              >
                <MdHelp className="w-6 h-6 text-brand-purple" />
              </button>
            </div>
          </div>
        </div>

        {showHelp && (
          <div ref={helpRef} className="absolute top-14 right-2 z-20 bg-white p-4 rounded shadow-md max-w-xs">
            <h3 className="font-bold mb-2">How to use the map:</h3>
            <ul className="list-disc pl-4 text-sm">
              <li>Enter an address or drag the pin to set your location.</li>
              <li>The purple circle shows your search area.</li>
              <li>Adjust the circle's edge or use the slider below to change your search range.</li>
              <li>Pharmacy icons will appear within your search area if available.</li>
            </ul>
          </div>
        )}

        <LocationMap request={request} />
      </div>
    </Section>
  );
};

export default Location;
