import HeroWaving from '@/assets/hero-waving.svg';
import Logo from '@/assets/logo.svg';

const ExternalWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <div className="flex flex-row w-full h-screen gap-4 sm:p-4">
        <div className="flex-1 hidden w-full bg-[#8D5BFF] bg-center bg-no-repeat lg:block rounded-2xl relative lg:w-1/3">
          <div className="flex flex-col items-center justify-center w-full gap-3 px-12 py-20 my-20 md:my-0 lg:my-20">
            <div className="text-white text-[58.66px] md:text-[48.66px] lg:text-[48.66px] font-black  leading-[58.66px] whitespace-nowrap">
              Needle Finds Your
            </div>
            <div className="inline-flex items-center justify-center rounded-full bg-emerald-400">
              <div className="px-[24.44px] pt-[14.66px] pb-[19.55px] bg-fuchsia-300 rounded-[610.42px] justify-center items-center gap-[6.11px] flex">
                <div className="text-center text-indigo-950 text-[58.66px] md:text-[48.66px] font-black  leading-[43.99px]">
                  ADHD
                </div>
              </div>
              <div className="pl-[9.78px] pr-[29.33px] pt-[14.66px] pb-[19.55px] justify-end items-center gap-[6.11px] flex">
                <div className="text-center text-indigo-950 text-[58.66px] md:text-[48.66px] font-black  leading-[43.99px]">
                  Medications
                </div>
              </div>
            </div>
            <div className="text-white text-[58.66px] font-black  leading-[58.66px] md:text-[48.66px]">in Stock</div>
          </div>

          <img src={HeroWaving} alt="Hero Waving" className="absolute bottom-0" />
        </div>
        <div className="flex-1 sm:bg-[#F8F8F8] rounded-2xl h-full">
          <div className="flex flex-col justify-between w-full h-full p-2 grow shrink-0 md:p-12">
            <div className="flex justify-center h-12">
              <img src={Logo} alt="Needle Logo" className="h-12 my-6" />
            </div>
            <div className="flex flex-col justify-center h-1/2 grow">
              <div className="">{children}</div>
            </div>
            <div className="flex justify-center flex-1 my-auto">
              <div className="my-auto text-base font-normal leading-normal text-center opacity-30 text-indigo-950">
                © {new Date().getFullYear()} Needle. All rights reserved.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExternalWrapper;
