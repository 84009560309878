import { useModal } from '@ebay/nice-modal-react';
import { CameraIcon, PlusIcon } from 'lucide-react';
import NoMed from '@/assets/hero-no-meds.svg';
import ListItem from '@/components/Medications/ListItem';
import { Button } from '@/components/ui/button';
import useMedications from '@/hooks/useMedications';
import { IRequest } from '@/types';
import Section from '../Section';
import { Table, TableBody } from '../ui/table';

interface ListProps {
  request: IRequest;
}

const List = ({ request }: ListProps) => {
  const medications = useMedications();
  const medicationModal = useModal('medication-modal');
  const photoModal = useModal('medication-photo-modal');

  const isMedicationInTasks = request.tasks?.length;

  const sortedMedications = request.medications?.sort((a, b) => a.medication.localeCompare(b.medication)) || [];

  return (
    <Section
      title="Medications"
      rightSide={
        <>
          {!isMedicationInTasks && (
            <div className="flex justify-end gap-2">
              <Button
                variant="outline"
                className="h-8 gap-2 px-2 text-sm rounded-full"
                onClick={() => photoModal.show({ request, medications: medications?.data })}
                disabled={medications?.isLoading}
                title="Add your prescription by uploading a photo"
              >
                <CameraIcon className="w-5 h-5 " />
              </Button>
              <Button
                className="h-8 gap-2 text-sm rounded-full whitespace-nowrap"
                onClick={() => medicationModal.show({ request, medications: medications?.data })}
                disabled={medications?.isLoading}
              >
                <PlusIcon className="w-4 h-4" />
                Add Medication
              </Button>
            </div>
          )}
        </>
      }
    >
      <div className="w-full overflow-x-scroll border border-gray-300 rounded-xl">
        <Table className="w-full text-xs text-gray-600">
          <TableBody>
            {sortedMedications.map((medication) => (
              <ListItem key={medication.id} medication={medication} request={request} />
            ))}
          </TableBody>
        </Table>
        {!sortedMedications.length && (
          <div className="p-4 text-lg text-center text-gray-600">
            <p className="mb-4">You have not added any medications</p>
            <button onClick={() => medicationModal.show({ request, medications: medications?.data })}>
              <img src={NoMed} alt="No Medications" className="mx-auto " />
            </button>
          </div>
        )}
      </div>
    </Section>
  );
};

export default List;
