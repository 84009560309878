import axios from 'axios';

const getToken = () => {
  const data = localStorage.getItem('Needle');
  if (data) {
    return JSON.parse(data).sessionId;
  }
  return null;
};

const instance = axios.create({
  baseURL: import.meta.env.VITE_NEEDLE_API_URL,
});

instance.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers['Needle-Token'] = token;
    }
    config.headers['Content-Type'] = 'application/json';
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Handle 401 errors
    if (error.response && error.response.status === 401) {
      window.location.href = '/login';
    }
    return Promise.reject(error);
  },
);

export default instance;
