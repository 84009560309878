import { useEffect } from 'react';
import posthog from 'posthog-js';

const Logout = () => {
  useEffect(() => {
    posthog.reset();
    localStorage.removeItem('Needle');
    window.location.href = '/login';
  }, []);

  return null;
};

export default Logout;
