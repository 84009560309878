const OnboardingSubscription = () => {


  return (
    <>
      Subscription
    </>
  );
};

export default OnboardingSubscription;
