import { useModal } from '@ebay/nice-modal-react';
import { useParams } from 'react-router-dom';
import HeroAvatar from '@/assets/hero_avatar.svg';
import useMe from '@/hooks/useMe.ts';
import { USDollar } from '@/utils/helpers.ts';
import { Button } from './ui/button';

function SubscriptionSummary() {
  const { requestId } = useParams();
  const currentUser = useMe();
  const creditsModal = useModal('credits-modal');

  if (currentUser.isLoading || !currentUser?.data) {
    return null;
  }

  const isMember = currentUser?.data?.membership_is_active;

  return (
    <div className="cursor-pointer" onClick={() => creditsModal.show({ requestId })}>
      {!isMember ? (
        <>
          <div className="w-full p-3 bg-[#8D5BFF] text-brand-dark-blue rounded-xl gap-2 flex flex-col">
            <div className="text-lg font-semibold leading-6 text-white ">Needle Monthly Subscription</div>
            <div className="text-sm text-gray-300">
              Enjoy 50 calls each month that never expire, with unused credits rolling over.
            </div>
            <Button className="w-full px-4 py-2 font-semibold text-center text-brand-dark-blue bg-[#FFDF7C]  rounded-full mt-2">
              Subscribe ($35)
            </Button>
          </div>
          <div className="grow shrink basis-0  justify-start items-center gap-2.5 flex mt-8">
            <img src={HeroAvatar} className="w-10 h-10 " />
            <div className="inline-flex flex-col items-start justify-start">
              <div className="text-white text-[15px] font-semibold  leading-snug">
                {currentUser.data?.first_name} {currentUser.data?.last_name}
              </div>
              <div className="text-sm font-medium leading-tight text-white opacity-80">
                Credit Balance: {USDollar.format(currentUser?.data?.total_credits)}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className=" rounded-[5px] shadow justify-start items-center gap-3 inline-flex">
            <div className="grow shrink basis-0  justify-start items-center gap-2.5 flex">
              <img src={HeroAvatar} className="w-10 h-10 " />
              <div className="inline-flex flex-col items-start justify-start">
                <div className="text-white text-[15px] font-semibold  leading-snug">
                  {currentUser.data?.first_name} {currentUser.data?.last_name}
                </div>
                <div className="text-sm font-medium leading-tight text-white opacity-80">
                  Credit Balance: {USDollar.format(currentUser?.data?.total_credits)}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default SubscriptionSummary;
