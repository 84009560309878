import { IRequest } from '@/types.ts';
import { getUrl } from '@/utils/api';

function useRequests(admin: boolean = false) {
  const url = admin ? `/admin/requests` : `v1/requests`;
  const query = getUrl(url);
  return {
    ...query,
    data: query.data as IRequest[],
  };
}

export default useRequests;
