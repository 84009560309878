import { loadStripe } from '@stripe/stripe-js';
import { DollarSign, HandMetal, IceCream, PartyPopper } from 'lucide-react';
import posthog from 'posthog-js';
import axiosClient from '@/utils/axiosClient.ts';
import { cn } from '@/utils/utils';

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);

const packages = [
  {
    description: 'Get started off with 10 credits. Great for trying out the service.',
    price: '$10.00',
    credits: 10,
    discount: 0,
    icon: <IceCream />,
    popular: false,
  },
  {
    description: 'Get 25 credits for $23.75. Saving you 5% off the regular price. Great for occasional users.',
    price: '$23.75',
    credits: 25,
    discount: 0.05,
    icon: <DollarSign />,
    popular: true,
  },
  {
    description: 'Saving you 10% off the regular price. For those that live in more populated areas.',
    price: '$45.00',
    credits: 50,
    discount: 0.1,
    icon: <PartyPopper />,
    popular: false,
  },
  {
    description: 'For our most active users needing large volumes of calls. Saving you 15% off the regular price.',
    price: '$85.00',
    credits: 100,
    discount: 0.15,
    icon: <HandMetal />,
    popular: false,
  },
];

interface CreditsModalProps {
  requestId?: string;
}

const CreditPackages = ({ requestId }: CreditsModalProps) => {
  const createCreditCheckoutSession = async (pkg: any) => {
    axiosClient.post('/v1/credit_packs', { credits: pkg.credits, request_id: requestId }).then((response) => {
      if (response?.data?.stripe_session_id) {
        stripePromise.then((stripe) => {
          if (stripe) {
            stripe.redirectToCheckout({
              sessionId: response.data.stripe_session_id,
            });
          }
        });
      }
    });
  };

  return (
    <>
      <div className="grid w-full grid-cols-1 gap-4 md:grid-cols-2 ">
        {packages.map((pack) => (
          <button
            key={pack.description}
            className={cn(
              'relative flex flex-col gap-6 p-4 border border-gray-300 rounded-lg cursor-pointer group  hover:border-purple-600 ',
              pack.popular ? 'bg-[#F0E9FF] border-purple-600 ' : 'bg-white ',
            )}
            onClick={() => {
              posthog.capture('checkout_credits', { credit_amount: pack.credits });
              createCreditCheckoutSession(pack);
            }}
          >
            <div className="absolute flex items-center gap-2 p-2 text-white border border-gray-300 rounded-full top-2 right-2 group-hover:border-purple-600 group-hover:bg-white" />

            <div className="flex flex-col gap-1 text-left grow">
              <div className="text-lg font-semibold ">{pack.credits} Credits</div>
              <div className="text-xs">{pack.description}</div>
            </div>
            <div
              className={cn(
                'block px-4 py-1 text-lg font-semibold bg-gray-200 rounded-full group-hover:hidden',
                pack.popular && 'hidden',
              )}
            >
              {pack.price}
            </div>
            <div
              className={cn(
                'bg-amber-200 rounded-[99px] justify-start items-center group-hover:block hidden',
                pack.popular && 'block',
              )}
            >
              <div className="px-4 py-2 bg-amber-200 rounded-[99px] flex-col justify-center items-start gap-8 inline-flex">
                <div className="text-base font-semibold leading-tight text-indigo-950">{pack.price}</div>
              </div>
              <div className="grow shrink basis-0 px-4 py-2 bg-violet-500 rounded-[99px] flex-col justify-center items-center gap-8 inline-flex">
                <div className="text-base font-semibold leading-tight text-white">Purchase</div>
              </div>
            </div>
          </button>
        ))}
      </div>
    </>
  );
};

export default CreditPackages;
