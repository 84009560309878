import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from '@/components/ui/dialog.tsx';
import { cn } from '@/utils/utils.ts';

interface ModalProps {
  title?: string;
  description?: string;
  children: React.ReactNode;
  modal: any;
  onClose?: () => void;
  wide?: boolean;
}

const Modal = ({ title, description, children, modal, onClose, wide = false }: ModalProps) => {
  if (!modal) {
    console.error('DEV ERROR: The modal you are trying to use MUST have a modal prop from useModal()');
  }
  const handleClose = () => {
    if (onClose) {
      onClose();
    }
    modal.remove();
  };

  return (
    <Dialog open={modal.visible} onOpenChange={handleClose}>
      <DialogContent
        onPointerDownOutside={() => modal.hide()}
        onEscapeKeyDown={() => modal.hide()}
        className={cn(
          ' w-full mx-auto p-4 max-h-[95vh]',
          'sm:w-[90vw] overflow-y-',
          wide ? 'md:max-w-4xl' : 'md:max-w-lg',
        )}
      >
        {(title || description) && (
          <DialogHeader>
            {title && <DialogTitle>{title}</DialogTitle>}
            {description && <DialogDescription>{description}</DialogDescription>}
          </DialogHeader>
        )}
        <div className="mt-2 overflow-y-auto max-h-[75vh] pb-20 sm:pb-4">{children}</div>
      </DialogContent>
    </Dialog>
  );
};

export default Modal;
