import { Navigate } from 'react-router';
import { useAuth } from '../providers/AuthProvider';
import SiteWrapper from './SiteWrapper';

const PrivateRoute = ({ children }: { children: React.ReactNode }) => {
  const authed = useAuth();
  return authed ? <SiteWrapper>{children}</SiteWrapper> : <Navigate to="/" />;
};

export default PrivateRoute;
