import React, { useState, ChangeEvent, FormEvent } from 'react';
import useMe from '@/hooks/useMe';
import AxiosClient from '@/utils/axiosClient';
import { Button } from '../ui/button';

interface EditUserInfoProps {
  field: 'name' | 'phone';
  onClose: () => void;
}

const EditUserInfo: React.FC<EditUserInfoProps> = ({ field, onClose }) => {
  const { data: currentUser, refetch } = useMe();
  const [formData, setFormData] = useState({
    first_name: currentUser?.first_name || '',
    last_name: currentUser?.last_name || '',
    phone: currentUser?.phone || '',
  });

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFormSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      console.log(`Updating user with ID: ${currentUser?.id}`);
      const response = await AxiosClient.put(`/v1/users/${currentUser?.id}`, formData);
      console.log('Update successful:', response);
      await refetch();
      onClose();
    } catch (error) {
      console.error('Failed to update user data', error);
    }
  };

  return (
    <form onSubmit={handleFormSubmit} className="flex flex-col gap-4">
      {field === 'name' && (
        <div className="flex flex-col gap-4">
          <div>
            <label htmlFor="first_name" className="text-sm font-medium text-slate-600">
              First Name:
            </label>
            <input
              type="text"
              id="first_name"
              name="first_name"
              value={formData.first_name}
              onChange={handleInputChange}
              className="w-40 p-3 mt-1 border rounded"
            />
          </div>
          <div>
            <label htmlFor="last_name" className="text-sm font-medium text-slate-600">
              Last Name:
            </label>
            <input
              type="text"
              id="last_name"
              name="last_name"
              value={formData.last_name}
              onChange={handleInputChange}
              className="w-40 p-3 mt-1 border rounded"
            />
          </div>
        </div>
      )}
      {field === 'phone' && (
        <div>
          <label htmlFor="phone" className="text-sm font-medium text-slate-600">
            Phone:
          </label>
          <input
            type="text"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleInputChange}
            className="w-full p-2 mt-1 border rounded"
          />
        </div>
      )}
      <Button type="submit" className="mt-4">
        Save
      </Button>
    </form>
  );
};

export default EditUserInfo;
