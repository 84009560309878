import { IMedication } from '@/types.ts';
import { getUrl } from '@/utils/api';

function useMedications() {
  const query = getUrl(`v1/medications`);
  return {
    ...query,
    data: query.data as IMedication[],
  };
}

export default useMedications;
