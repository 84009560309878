import NiceModal, { useModal } from '@ebay/nice-modal-react';
import Modal from '@/components/Modals/Modal';
import PharmacyResults from '@/components/Requests/PharmacyResults';
import { IMedication, IRequest } from '@/types';

interface MedicationResultsModalProps {
  request: IRequest;
  medication: IMedication;
}

const MedicationResultsModal = NiceModal.create(({ request, medication }: MedicationResultsModalProps) => {
  const modal = useModal('medication-results-modal');
  return (
    <Modal modal={modal} title={'Pharmacy Availability'} wide>
      <PharmacyResults request={request} medication={medication} />
    </Modal>
  );
});

export default MedicationResultsModal;
