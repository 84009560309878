/* eslint-disable */
// @ts-nocheck
import { useQuery } from 'react-query';

export default function useRequestProcessor() {
  function query(key: string, queryFunction: (url: string) => Promise<any>, options = {}) {
    return useQuery({
      queryKey: key,
      queryFn: queryFunction,
      ...options,
    });
  }

  return { query };
}
