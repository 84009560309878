import { Menu } from 'lucide-react';
import { useSetRecoilState } from 'recoil';
import Logo from '@/assets/logo-white.svg';
import state from '@/utils/State';

const MobileSidebarButton = () => {
  const setSidebarOpen = useSetRecoilState(state.sidebarOpen || false);
  return (
    <>
      <div className="sticky top-0 z-40 w-full h-12 shadow-sm bg-[#221A51] md:hidden ">
        <div className="absolute top-2.5 left-2">
          <button type="button" className=" md:hidden" onClick={() => setSidebarOpen(true)}>
            <Menu className="w-6 h-6 text-white" aria-hidden="true" />
          </button>
        </div>
        <div className="flex items-center justify-center w-full px-2 py-2 ">
          <img src={Logo} alt="Needle Logo" className="text-center h-7" onClick={() => window.location.reload()} />
        </div>
      </div>
    </>
  );
};

export default MobileSidebarButton;
