const classNames = (...classes: any[]) => {
  return classes.filter(Boolean).join(' ');
};

const timeSaved = (seconds: any) => {
  const hours = Math.floor(((seconds % 31536000) % 86400) / 3600);
  const minutes = Math.floor((((seconds % 31536000) % 86400) % 3600) / 60);

  let time = '';
  if (hours === 0 && minutes === 0) time = `about a minute`;
  if (hours === 0 && minutes > 0) time = `${minutes} minutes`;
  if (hours === 1 && minutes === 0) time = `an hour`;
  if (hours === 1 && minutes > 0) time = `an hour and ${minutes} minutes`;
  if (hours > 1 && minutes === 0) time = `${hours} hours`;
  if (hours > 1 && minutes > 0) time = `${hours} hours and ${minutes} minutes`;

  return time;
};

const statusMap = [
  {
    status: 'unpaid',
    label: 'Uncomplete',
    buttonLabel: 'Continue Building Order',
    class: 'bg-red-500 border-red-400 text-white',
  },
  {
    status: 'active',
    label: 'In Request Queue',
    buttonLabel: 'View Details',
    class: 'bg-blue-600 border-blue-900 text-white',
  },
  {
    status: 'pending_billing',
    label: 'Pending Billing',
    buttonLabel: 'Click here to complete payment',
    class: 'bg-red-500 border-red-400 text-white',
  },
  {
    status: 'pending',
    label: 'Calls In Progress',
    buttonLabel: 'View Details',
    class: 'bg-blue-700 border-blue-900 text-white',
  },
  {
    status: 'complete',
    label: 'Order Fulfilled',
    buttonLabel: 'View Completed Order',
    class: 'bg-green-600 border-green-900 text-white',
  },
];

const formatPhoneNumber = (phoneNumber: string) => {
  if (!phoneNumber) return '';
  if (phoneNumber?.startsWith('+1')) phoneNumber = phoneNumber.slice(2);
  const cleaned = ('' + phoneNumber).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }

  return phoneNumber;
};

const USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const formatDateTime = (dateTimeString: string | number | Date) => {
  if (!dateTimeString) return 'N/A';

  let date;
  if (typeof dateTimeString === 'string') {
    if (dateTimeString.includes('UTC')) {
      date = new Date(dateTimeString.replace(' ', 'T').replace(' UTC', 'Z'));
    } else {
      date = new Date(dateTimeString);
    }
  } else {
    date = new Date(dateTimeString);
  }

  // Handle any unexpected invalid date
  if (isNaN(date.getTime())) return 'N/A';

  return date.toLocaleString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  });
};

const formatDateMMDDYYYY = (dateString: string | number | Date): string => {
  if (!dateString) return 'N/A';

  let date: Date;
  if (typeof dateString === 'string') {
    if (dateString.includes('UTC')) {
      date = new Date(dateString.replace(' ', 'T').replace(' UTC', 'Z'));
    } else {
      date = new Date(dateString);
    }
  } else {
    date = new Date(dateString);
  }

  if (isNaN(date.getTime())) return 'N/A';

  return date.toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  });
};

export { classNames, timeSaved, statusMap, formatPhoneNumber, USDollar, formatDateTime, formatDateMMDDYYYY };
